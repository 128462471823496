.circular-icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.circular-icon {
  width: 100px;
  height: 100px;
  background: linear-gradient(to right, rgb(236, 72, 153), rgb(139, 92, 246));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.skill-category {
  margin: 40px 0;
  text-align: center;
}

.skill-category h2 {
  font-size: 44px;
  color: #fff;
  margin-bottom: 20px;
}
