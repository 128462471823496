@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	background-color: black;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	scroll-behavior: smooth;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.iam {
	overflow: hidden; /* Ensures the content is not revealed until the animation */
	border-right: 1rem solid rgb(251 191 36 / var(--tw-text-opacity)); /* The typwriter cursor */
	white-space: nowrap; /* Keeps the content on a single line */
	width: 0;
	animation: typing 5s steps(45, end) forwards infinite,
		blink-caret 0.75s 0.25s step-end infinite;
	max-width: min-content;
}

/* The typing effect */
@keyframes typing {
	0%,
	100% {
		width: 0;
		padding-right: 0;
	}
	50% {
		width: 100%;
		padding-right: 4px;
	}
	90% {
		width: 0;
	}
}

/* The typewriter cursor effect */
@keyframes blink-caret {
	from,
	to {
		border-color: transparent;
	}
	50% {
		border-color: rgb(251 191 36 / var(--tw-text-opacity));
	}
}

/* "homepage": "https://inderpreet-react.github.io/portfolio/", */
